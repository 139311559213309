export const locale = {
  lang: "ja",
  localeData: {
    "userlist": "User List",
    "spaceOwnerList": "Space Owner List",
    "languagesetting": "言語設定",
    "logout": "ログアウト",
    "save": "保存する",
    "email": "電子メール",
    "emailPlaceholder": "example@example.com",
    "send": "Send",
    "login": {
      "capital": "ログイン",
      "lower": "ログイン",
      "pascal": "ログイン"
    },
    "editProfile": {
      "edit": "プロフィール編集",
    },
    "userList": {
      "title": "Space Owner List",
      "btnInviteUser": "Invite User",
      "searchUsers": "Search for Users",
      "gridHeaderName": "Name",
      "gridHeaderEmail": "Email",
      "gridHeaderRole": "Role"
    },
    "inviteMember": {
      "title": "ユーザー招待"
    }
  }
};

