<div class="login-page">
  <div class="login-section">
    <div class="login-welcome">
      <img src="assets/images/Diva-Cloud-Logo-1.png">
    </div>
    <div class="login-form">
      <form>
        <div class="button-row">
          <button mat-raised-button color="primary" class="submit-button" aria-label="LOGIN"
                (click)="login()">
          {{ 'login.capital' | translate }}
        </button>
        </div>
      </form>
    </div>
  </div>
</div>
