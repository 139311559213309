import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { takeUntil } from "rxjs/operators";
import { SharedSettings } from "@app/shared/setting/shared-settings";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {
  private unsubscribeAll = new Subject<any>();
  public isLoading: boolean;

  /**
  * Constructor
  * @param {AUTH_SERVICE} authService
  * @param {Router} router
  */

  constructor(
    public authService: AuthService,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.authService.isAuthenticated$
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe((loggedIn) => {
        // check error code in the query parameter from login.callback.component
        // http://---/index?errorcode=9000
        const queryParams = this.activatedRoute.snapshot.queryParams;
        let errorCode = queryParams["errorcode"];

        if (loggedIn && !errorCode) {
          this.router.navigate([SharedSettings.userListRoute]);
        } else {
          document.getElementById("global-spinner").style.display = "none";
        }
        this.isLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.unsubscribe();
  }

  login(redirectPath: string = "") {
    this.authService.loginWithRedirect({
      appState: { target: redirectPath},
      authorizationParams: {
        /* eslint-disable camelcase */
        redirect_uri: `${window.location.origin}/logincallback`,
        ui_locales: localStorage.getItem("localStoreLang"),
        /* eslint-enable camelcase */
      },
    });
  }
}
