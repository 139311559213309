import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { TranslationLoaderService } from "./shared/services/translation-loader.service";
import { TranslateService } from "@ngx-translate/core";
import { DOCUMENT } from "@angular/common";
import { SharedSettings } from "./shared/setting/shared-settings";
import { locale as translateEnglish } from "../assets/i18n/en";
import { locale as translateJapanese } from "../assets/i18n/ja";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(DOCUMENT) private document: any,
    private translate: TranslateService,
    private translationLoaderService: TranslationLoaderService
  ) {
    const localStoreLang: string = localStorage.getItem("localStoreLang");
    const browserLang: string = this.translate.getBrowserLang();

    this.translate.addLangs(SharedSettings.getLanguagesIdInArray);
    // set default language for app
    this.translate.setDefaultLang(
      localStoreLang ? localStoreLang
        : browserLang.match(SharedSettings.getLanguagesIdInArray.join("|"))
          ? browserLang : SharedSettings.defaultLanguage
    );
    // Set the navigation translations
    this.translationLoaderService.loadTranslations(translateEnglish, translateJapanese);
  }
  title = "Diva Operator";
  ngOnInit(): void {
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {

  }
}
