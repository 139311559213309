import { languages } from "@shared/const/lang";
import { AppConfig } from "@app/config/app.config";

export class SharedSettings {
  public static get defaultLanguage(): string {
    let languageIndex = 1;

    return languages[languageIndex].id;
  }

  public static get getLanguagesIdInArray(): Array<string> {
    return languages.map(language => language.id);
  }

  public static get userListRoute(): any {
    return "dashboards/user-list";
  }

  // --------------individual service urls --------------------------//
  public static get accountServiceUrl(): any {
    return `${AppConfig.settings.accountServiceUrl}`;
  }
  public static get domain(): any {
    return `${AppConfig.settings.domain}`;
  }
  public static get clientId(): any {
    return `${AppConfig.settings.clientId}`;
  }
  public static get audience(): any {
    return `${AppConfig.settings.audience}`;
  }
}
