export const locale = {
  lang: "en",
  localeData: {
    "userlist": "User List",
    "spaceOwnerList": "Space Owner List",
    "languagesetting": "Language Settings",
    "logout": "Logout",
    "save": "Save",
    "email": "Email",
    "emailPlaceholder": "example@example.com",
    "send": "Send",
    "login": {
      "capital": "LOGIN",
      "lower": "login",
      "pascal": "Login",

    },
    "editProfile": {
      "edit": "Edit Profile",
    },
    "userList": {
      "title": "Space Owner List",
      "btnInviteUser": "Invite User",
      "searchUsers": "Search for Users",
      "gridHeaderName": "Name",
      "gridHeaderEmail": "Email",

    },
    "inviteMember": {
      "title": "Invite User",
    }
  }
};

