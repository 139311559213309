import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { mergeMap, catchError } from "rxjs/operators";
import { AuthService } from "@auth0/auth0-angular";

@Injectable({
  providedIn: "root"
})
export class UnauthorizedRequestInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has("NoAuthValidate")) {
      return this.auth.getAccessTokenSilently().pipe(
        mergeMap(token => {
          let space = localStorage.getItem("userSpaceID");

          if(!space) {
            space="";
          }
          const tokenReq = req.clone({
            setHeaders: { Authorization: `Bearer ${token}`, Space: space }
          });

          return next.handle(tokenReq);
        }),
        catchError(error => {
          const unauthorizedErrorResponseCode = 401;

          if (error.status === unauthorizedErrorResponseCode) {
            this.auth.logout();
          }
          if (error.error !== null) {
            // if (!tokenRe.headers.has(ConflowUtils.headerToSkipHttpInterceptorErrorPopup)) {
            // this.alertService.showWarning(error.error.title, 'Error Occurred!', 'OK', 2000);
            // }
          }
          return throwError(() => error);
        }));
    }
    return next.handle(req);
  }
}
