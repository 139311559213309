import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginCallbackComponent } from "./core/auth/logincallback/login.callback.component";
import { LoginComponent } from "./core/auth/login/login.component";
import { AuthGuard } from "@auth0/auth0-angular";

export const routes: Routes = [
  {
    path: "",
    redirectTo: "index",
    pathMatch: "full"
  },
  {
    path: "index",
    component: LoginComponent
  },
  {
    path: "logincallback",
    component: LoginCallbackComponent
  },
  {
    path: "dashboards",
    canActivate: [AuthGuard],
    loadChildren: () => import("./features/features.module")
      .then(module => module.FeaturesModule),
    runGuardsAndResolvers: "always"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
