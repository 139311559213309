import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { AuthGuard } from "@auth0/auth0-angular";
import { AuthConfigService } from "@auth0/auth0-angular";
import { AuthModule } from "@auth0/auth0-angular";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import { UnauthorizedRequestInterceptor } from "./shared/http-interceptor.service";
import { HTTP_INTERCEPTORS, HttpClient } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { DivaLibModule } from "diva-lib";
import { OverlayContainer, FullscreenOverlayContainer } from "@angular/cdk/overlay";
import { AppConfig } from "./config/app.config";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MAT_FORM_FIELD } from "@angular/material/form-field";
import { SharedSettings } from "./shared/setting/shared-settings";

const initializeApp = (appConfig: AppConfig) => {
  return () => appConfig.load();
};

export default initializeApp;

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot(),
    CoreModule.forRoot(),
    SharedModule,
    BrowserAnimationsModule,
    CommonModule,
    DivaLibModule,
    MatSortModule,
    MatTableModule,
    AuthModule.forRoot(),
  ],
  providers: [
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig, HttpClient], multi: true
    },
    AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedRequestInterceptor, multi: true },
    { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    { provide: MAT_FORM_FIELD, useValue: {appearance: "outline"}},
    {
      provide: AuthConfigService,
      useFactory: () => ({
        domain: SharedSettings.domain,
        clientId: SharedSettings.clientId,
        authorizationParams: {
          audience: SharedSettings.audience,
          /* eslint-disable camelcase */
          redirect_uri: `${window.location.origin}/logincallback`,
          ui_locales: localStorage.getItem("localStoreLang"),
          /* eslint-enable camelcase */
          httpInterceptor: {
            allowedList: [`${SharedSettings.audience}*`],
          },
        },
      }),
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
